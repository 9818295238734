.cattos-list figcaption p {
    margin-bottom: 0;
}

.cattos-list figcaption p {
    margin-bottom: 0;
}

.catto-details p {
    margin-bottom: 0;
}

.timeline .divider {
    border-bottom: 50px solid #888888;
    width: 1px;
    margin: 1.5rem auto;
}

.timeline figure img {
    max-width: 150px;
}
