.site-header .branding .text {
    font-size: 3rem;
}

.site-header .navs li {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.3rem;
    margin-left: 1rem;
}

.site-header .navs .menu li {
    font-size: 1.1rem;
    margin-left: 1.5rem;
}

.site-header .navs .socials li:first-of-type {
    margin-left: 0;
}

.site-header .navs .menu li:first-of-type {
    margin-left: 0;
}
